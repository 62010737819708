<script lang="ts">
import OzBox, { OzBoxColors } from '@@/library/v4/components/OzBox.vue'
import OzContainedButton, { type OzContainedButtonColorScheme } from '@@/library/v4/components/OzContainedButton.vue'
import { type ComponentPublicInstance, onMounted, ref } from 'vue'

export interface OzBaseDialogBoxButtonData {
  text: string
  emit: string
  colorScheme: OzContainedButtonColorScheme
  testId?: string
  disabled?: boolean
  isFocusedOnLoad?: boolean
}

export { OzContainedButtonColorScheme } from '@@/library/v4/components/OzContainedButton.vue'
export default {}
</script>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    buttons: OzBaseDialogBoxButtonData[]
    darkMode?: boolean | 'auto'
    forceFullWidthButtons?: boolean
  }>(),
  {
    darkMode: 'auto',
    forceFullWidthButtons: false,
  },
)

const buttonWrapperRefs = ref<Record<string, HTMLDivElement>>({})
const setButtonWrapperRef = (
  buttonWrapperInstance: HTMLDivElement | ComponentPublicInstance | null,
  buttonWrapperIndex: string,
): void => {
  buttonWrapperRefs.value[buttonWrapperIndex] = buttonWrapperInstance as HTMLDivElement
}

onMounted((): void => {
  props.buttons.forEach((button, index) => {
    if (button.isFocusedOnLoad) {
      ;(buttonWrapperRefs.value[index]?.children[0] as HTMLElement)?.focus()
    }
  })
})
</script>

<template>
  <OzBox
    :color="OzBoxColors.Primary"
    :radius="24"
    :dark-mode="darkMode"
    :class="[
      // Shape
      'w-82',
    ]"
    role="dialog"
    aria-modal="true"
  >
    <!-- @slot Dialog box content. -->
    <slot></slot>

    <div class="px-3 pb-4 flex flex-wrap">
      <!--
        This wrapper div allows us to size OzContainedButton more easily,
        since it's easier to factor padding into sizing than margins.
      -->
      <div
        v-for="(button, index) in buttons"
        :ref="
          (el) => {
            setButtonWrapperRef(el, index)
          }
        "
        :key="button.text"
        class="p-1 max-w-full box-border"
        :style="{ flex: `1 0 ${forceFullWidthButtons ? 100 : 100 / buttons.length}%` }"
      >
        <OzContainedButton
          class="box-border w-full whitespace-nowrap"
          :data-testid="button.testId"
          :data-pw="button.testId"
          :dark-mode="darkMode"
          :color-scheme="button.colorScheme"
          :text="button.text"
          :disabled="button.disabled"
          @click.stop="$emit(button.emit)"
        />
      </div>
    </div>
  </OzBox>
</template>
